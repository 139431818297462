'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Button, Stack } from '@mui/material';
import { CheckboxInput, Link, PasswordInput, type PasswordRequirementT } from '@prismo-io/design-system';
import { type FC, useMemo } from 'react';
import { type DeepPartial, type SubmitHandler, useForm } from 'react-hook-form';
import { ApplyPasswordRefine, SecurityStepSchema, type SecurityStepSchemaT } from '../schema';
type SignUpSecurityStepProps = {
  defaultValues?: DeepPartial<SecurityStepSchemaT>;
  onSubmit: SubmitHandler<SecurityStepSchemaT>;
  haveNextStep?: boolean;
  needCGV?: boolean;
};
const DEFAULT_VALUES: SecurityStepSchemaT = {
  password: '',
  confirmPassword: '',
  terms: false as true
};
export const SignUpSecurityStepForm: FC<SignUpSecurityStepProps> = props => {
  const {
    onSubmit,
    haveNextStep = false,
    needCGV = false,
    defaultValues = {}
  } = props;
  const {
    control,
    handleSubmit,
    formState: {
      isValid
    }
  } = useForm<SecurityStepSchemaT>({
    mode: 'all',
    resolver: zodResolver(ApplyPasswordRefine(SecurityStepSchema())),
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues
    }
  });
  const {
    i18n
  } = useLingui();
  const passwordRequirements = useMemo((): PasswordRequirementT[] => [{
    re: /^[\S]{8,}$/,
    label: t`Doit comporter 8 charactères au minimum`
  }, {
    re: /[0-9]/,
    label: t`Doit comporter un chiffre`
  }, {
    re: /[a-z]/,
    label: t`Doit comporter une minuscule`
  }, {
    re: /[A-Z]/,
    label: t`Doit comporter une majuscule`
  }
  // { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: t`Includes special symbol` },
  ], [i18n.locale]);
  return <Stack spacing={2} component="form" noValidate onSubmit={handleSubmit(onSubmit)} data-sentry-element="Stack" data-sentry-component="SignUpSecurityStepForm" data-sentry-source-file="security.step.tsx">
      <PasswordInput control={control} name="password" label={t`Mot de passe`} size="small" required requirements={passwordRequirements}
    // eslint-disable-next-line jsx-a11y/no-autofocus
    autoFocus data-sentry-element="PasswordInput" data-sentry-source-file="security.step.tsx" />
      <PasswordInput control={control} name="confirmPassword" label={t`Confirmation du mot de passe`} size="small" required data-sentry-element="PasswordInput" data-sentry-source-file="security.step.tsx" />
      <CheckboxInput control={control} name="terms" label={needCGV ? <Trans>
              J&apos;accepte les&nbsp;
              <Link href="https://prismo.io/cgu/" target="_blank" color="primary.light">
                Conditions Générales d'Utilisation
              </Link>
              &nbsp;, les&nbsp;
              <Link href="https://prismo.io/cgv/" target="_blank" color="primary.light">
                Conditions Générales de Vente
              </Link>
              &nbsp;, et la&nbsp;
              <Link href="https://prismo.io/politique-confidentialite/" target="_blank" color="primary.light">
                Politique de confidentialité
              </Link>
              .
            </Trans> : <Trans>
              J&apos;accepte les&nbsp;
              <Link href="https://prismo.io/cgu/" target="_blank" color="primary.light">
                Conditions Générales d'Utilisation
              </Link>
              &nbsp;, et la&nbsp;
              <Link href="https://prismo.io/politique-confidentialite/" target="_blank" color="primary.light">
                Politique de confidentialité
              </Link>
              .
            </Trans>} required data-sentry-element="CheckboxInput" data-sentry-source-file="security.step.tsx" />
      <Button type="submit" disabled={!isValid} variant="contained" color="primary" data-sentry-element="Button" data-sentry-source-file="security.step.tsx">
        {haveNextStep ? t`Suivant` : t`M'inscrire`}
      </Button>
    </Stack>;
};
'use client';

import { useApi } from '@prismo-io/core';
import type { CheckEmailVerifyCodeArgsT } from '@prismo-io/schemas';
import useSWRMutation from 'swr/mutation';
import { checkVerificationCode } from './check-verification-code';
export const useCheckVerificationCode = (args: CheckEmailVerifyCodeArgsT) => {
  const {
    client
  } = useApi();
  return useSWRMutation(['check-verification-code'], key => checkVerificationCode(client, args));
};
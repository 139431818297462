'use client';

import { Trans } from '@lingui/macro';
import { Box, Button, CircularProgress, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { PoConnector, PoStepIcon } from '@prismo-io/design-system';
import { ComingFromType, type ComingFromTypeT } from '@prismo-io/schemas';
import { ApiClient } from '@prismo-io/schemas';
import { useRouter } from 'next/navigation';
import { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import type { DeepPartial, SubmitHandler } from 'react-hook-form';
import { useSignUpWithPassword } from '../../../hooks';
import { Chat } from '../../chat';
import { type InformationsStepSchemaT, type SecurityStepSchemaT, SignUpFormSchema, type SignUpFormSchemaT, type VerificationCodeSchemaT } from './schema';
import { SignUpInformationsStepForm } from './steps/informations.step';
import { SignUpSecurityStepForm } from './steps/security.step';
import { useCheckIfUserEmailIsPro } from './steps/use-check-if-user-email-is-pro';
import { VerificationCodeStepForm } from './steps/verification-code.step';
type SignUpFormProps = {
  successUrl: string;
  comingFrom?: ComingFromTypeT;
  campaign?: string;
  defaultValues?: DeepPartial<SignUpFormSchemaT>;
  configuration?: {
    withLeadChecking?: boolean;
    withVerification?: boolean;
    withRestrictDomain?: boolean;
    restrictDomain?: string[];
    withRestrictEmail?: boolean;
    restrictEmail?: string[];
  };
};
export const SignUpForm: FC<SignUpFormProps> = props => {
  const {
    defaultValues = {},
    successUrl,
    comingFrom,
    campaign,
    configuration = {}
  } = props;
  const {
    withLeadChecking = true,
    withVerification = false,
    withRestrictDomain = false,
    restrictDomain = [],
    withRestrictEmail = false,
    restrictEmail = []
  } = configuration;
  const [values, setFormValues] = useState<DeepPartial<SignUpFormSchemaT>>({
    ...defaultValues,
    with_restrict_domain: withRestrictDomain,
    restrict_domain: restrictDomain,
    with_restrict_email: withRestrictEmail,
    restrict_email: restrictEmail,
    verificationCode: {
      with_verification: withVerification
    },
    comingFrom: comingFrom,
    campaign: campaign,
    community_variation: undefined
  });
  const [activeStep, setActiveStep] = useState<number>(0);
  const {
    data: isProEmail
  } = useCheckIfUserEmailIsPro(withLeadChecking ? values?.email ?? undefined : undefined);
  const isMailPro = useMemo(() => withLeadChecking && isProEmail, [isProEmail, withLeadChecking]);
  const totalStep = useMemo(() => withVerification || isMailPro ? 3 : 2, [withVerification, isMailPro]);
  const router = useRouter();
  useEffect(() => {
    setFormValues(prev => ({
      ...prev,
      community_variation: isMailPro ? 'sequence' : undefined
    }));
  }, [isMailPro]);
  const {
    trigger: signUp,
    isMutating: isSubmitting,
    error,
    reset
  } = useSignUpWithPassword();
  const onInformationsStepSubmit: SubmitHandler<InformationsStepSchemaT> = data => {
    setFormValues(prev => ({
      ...prev,
      ...data
    }));
    setActiveStep(prev => prev + 1);
  };
  const onSecurityStepSubmit: SubmitHandler<SecurityStepSchemaT> = data => {
    setFormValues(prev => ({
      ...prev,
      ...data
    }));
    setActiveStep(prev => prev + 1);
  };
  const onVerificationCodeStepSubmit: SubmitHandler<VerificationCodeSchemaT> = data => {
    setFormValues(prev => ({
      ...prev,
      ...data
    }));
    setActiveStep(prev => prev + 1);
  };
  const onRestart = useCallback(() => {
    reset();
    setActiveStep(0);
  }, [setActiveStep, reset]);
  useEffect(() => {
    if (activeStep === totalStep) {
      const validValues = SignUpFormSchema.parse(values);
      signUp(validValues, {
        onSuccess: () => {
          router.push(successUrl);
        }
      });
    }
  }, [activeStep, values, totalStep]);
  return <Stack spacing={2} data-sentry-element="Stack" data-sentry-component="SignUpForm" data-sentry-source-file="form.tsx">
      <Stepper activeStep={activeStep} connector={<PoConnector />} data-sentry-element="Stepper" data-sentry-source-file="form.tsx">
        {new Array(totalStep).fill(null).map((_, idx) => <Step key={idx}>
            <StepLabel StepIconComponent={PoStepIcon} />
          </Step>)}
      </Stepper>

      {activeStep === 0 && <SignUpInformationsStepForm onSubmit={onInformationsStepSubmit} defaultValues={defaultValues} haveNextStep configuration={{
      withRestrictDomain,
      restrictDomain,
      withRestrictEmail,
      restrictEmail
    }} />}
      {activeStep === 1 && <SignUpSecurityStepForm onSubmit={onSecurityStepSubmit} defaultValues={defaultValues} haveNextStep={withVerification || isMailPro} needCGV={comingFrom === ComingFromType.Enum.ADMIN_INVITE || comingFrom === ComingFromType.Enum.TOOLS} />}
      {activeStep === 2 && (withVerification || isMailPro) && !!values.email && <VerificationCodeStepForm onSubmit={onVerificationCodeStepSubmit} defaultValues={defaultValues} email={values.email} firstName={values.firstName} />}
      {isSubmitting && !error && <Stack alignItems="center" spacing={2}>
          <CircularProgress />
          <Typography variant="subtitle1">
            <Trans>Votre compte est en cours de création.</Trans>
          </Typography>
          <p>
            <Trans>Vous allez être redirigé automatiquement...</Trans>
          </p>
        </Stack>}
      {/* <Box component="div" position="absolute" top={0} right={16} visibility={error ? 'visible' : 'hidden'}>
        <Chat visible={!!error} />
       </Box> */}
      {error && <Stack alignItems="center" spacing={2}>
          <Typography variant="subtitle1" color="error">
            {error instanceof Error && error.message === 'User already registered' ? <Trans>
                Cette adresse email est déjà associée à un utilisateur.
              </Trans> : (error as any)['code'] === 'weak_password' ? <Trans>
                Le mot de passe est trop faible, veuillez en choisir un autre.
              </Trans> : <Trans>Une erreur est survenue.</Trans>}
          </Typography>
          <Button variant="contained" color="gray" onClick={onRestart}>
            <Trans>Recommencer</Trans>
          </Button>
        </Stack>}
    </Stack>;
};
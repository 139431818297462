'use client';

import PrismoLogo from '@/assets/logos/prismo/light.svg';
import { Trans, t } from '@lingui/macro';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Button, SocialLogins } from '@prismo-io/design-system';
import type { ComingFromTypeT } from '@prismo-io/schemas';
import Image from 'next/image';
import Link from 'next/link';
import { type FC, useMemo } from 'react';
import { SignUpForm } from '../../components/forms/sign-up';
import { SignPaperCard } from '../../components/sign-paper-card';
import { useEmailLinkError, usePoleEmploi } from '../../hooks';
import { useSignWithLinkedIn } from '../../hooks/use-sign-with-linkedin';
type Props = {
  type?: 'socials-only' | 'password-only' | 'all';
  email?: string;
  signInUrl: string;
  successUrl: string;
  comingFrom?: ComingFromTypeT;
  campaign?: string;
  configuration?: {
    withLeadChecking?: boolean;
    withVerification?: boolean;
    withRestrictDomain?: boolean;
    withRestrictEmail?: boolean;
    withLinkedInConnect?: boolean;
    withPoleEmploiConnect?: boolean;
    restrictDomain?: string[];
    restrictEmail?: string[];
  };
};
export const FormCard: FC<Props> = props => {
  const {
    type = 'all',
    email = undefined,
    signInUrl,
    successUrl,
    configuration = {},
    comingFrom,
    campaign
  } = props;
  const {
    trigger: signWithLinkedIn
  } = useSignWithLinkedIn();
  const onLinkedInSubmit = () => {
    signWithLinkedIn();
  };
  const {
    trigger: goToPoleEmploi
  } = usePoleEmploi();
  const onPoleEmploiSubmit = () => {
    goToPoleEmploi();
  };
  useEmailLinkError();
  const someSocialAreEnable = useMemo(() => (configuration.withLinkedInConnect ?? true) || (configuration.withPoleEmploiConnect ?? true), [configuration.withLinkedInConnect, configuration.withPoleEmploiConnect]);
  const socialConfiguration = useMemo(() => {
    if (type !== 'socials-only') {
      return configuration;
    }
    if (someSocialAreEnable) {
      return configuration;
    }
    return undefined;
  }, [type, configuration, someSocialAreEnable]);
  return <SignPaperCard display="block" data-sentry-element="SignPaperCard" data-sentry-component="FormCard" data-sentry-source-file="form-card.tsx">
      <Stack gap={2} paddingY={{
      none: 5,
      sm: 3
    }} paddingX={3} height="100%" flex={1} position="relative" data-sentry-element="Stack" data-sentry-source-file="form-card.tsx">
        <Box component="div" position="relative" height={25} display={{
        none: 'none',
        sm: 'block'
      }} data-sentry-element="Box" data-sentry-source-file="form-card.tsx">
          <Image src={PrismoLogo} alt={t`Logo de PRISMO`} fill loading="eager" data-sentry-element="Image" data-sentry-source-file="form-card.tsx" />
        </Box>

        <Typography variant="h1" textAlign="center" data-sentry-element="Typography" data-sentry-source-file="form-card.tsx">
          <Trans data-sentry-element="Trans" data-sentry-source-file="form-card.tsx">Inscription</Trans>
        </Typography>

        {type !== 'socials-only' && <>
            <SignUpForm defaultValues={{
          email
        }} successUrl={successUrl} configuration={configuration} comingFrom={comingFrom} campaign={campaign} />

            <Stack direction="row" alignItems="center" justifyContent="center">
              <Trans>Vous avez un compte ?</Trans>{' '}
              <Button LinkComponent={Link} variant="text" color="inherit" href={signInUrl}>
                <Trans>Connexion</Trans>
              </Button>
            </Stack>
          </>}

        {type === 'all' && someSocialAreEnable && <Divider sx={{
        fontSize: 'fontSize'
      }}>
            <Trans>ou</Trans>
          </Divider>}
        {type !== 'password-only' && (someSocialAreEnable || type === 'socials-only') && <Stack spacing={2} alignItems="center">
              <SocialLogins onLinkedIn={onLinkedInSubmit} onPoleEmploi={onPoleEmploiSubmit} configuration={socialConfiguration} />
            </Stack>}
      </Stack>
    </SignPaperCard>;
};
import { Paper } from '@mui/material';
import type { CSSObject, Theme } from '@mui/material/styles';
import type { FC, ReactNode } from 'react';
type SignPaperCardProps = {
  children: ReactNode;
  display?: CSSObject['display'];
};
export const SignPaperCard: FC<SignPaperCardProps> = props => {
  const {
    children,
    display = 'flex'
  } = props;
  return <Paper elevation={3} sx={(theme: Theme) => ({
    width: '100vw',
    height: '100vh',
    display: display,
    position: 'relative',
    alignItems: 'center',
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 1,
      width: '100%',
      maxWidth: '26rem',
      height: 'auto',
      maxHeight: '100vh'
    }
  })} data-sentry-element="Paper" data-sentry-component="SignPaperCard" data-sentry-source-file="sign-paper-card.tsx">
      {children}
    </Paper>;
};
'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { Trans, t } from '@lingui/macro';
import { Button, Stack, Typography } from '@mui/material';
import { OtpInput } from '@prismo-io/design-system';
import useRunOnce from '@prismo-io/utils/client/use-run-once';
import { type FC, useCallback } from 'react';
import { type DeepPartial, type SubmitHandler, useForm } from 'react-hook-form';
import { VerificationCodeSchema, type VerificationCodeSchemaT } from '../schema';
import { useCheckVerificationCode } from './use-check-verification-code';
import { useSendVerificationCode } from './use-send-verification-code';
type VerificationCodeStepProps = {
  email: string;
  firstName?: string;
  defaultValues?: DeepPartial<VerificationCodeSchemaT>;
  onSubmit: SubmitHandler<VerificationCodeSchemaT>;
  haveNextStep?: boolean;
};
const DEFAULT_VALUES: VerificationCodeSchemaT = {
  verificationCode: {
    with_verification: true,
    code: ''
  }
};
export const VerificationCodeStepForm: FC<VerificationCodeStepProps> = props => {
  const {
    onSubmit: onSuccess,
    email,
    firstName,
    haveNextStep = false,
    defaultValues = {}
  } = props;
  const {
    control,
    handleSubmit,
    formState: {
      isValid,
      isSubmitSuccessful
    },
    watch,
    setError
  } = useForm<VerificationCodeSchemaT>({
    mode: 'all',
    resolver: zodResolver(VerificationCodeSchema()),
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues
    }
  });
  const code = watch('verificationCode.code');
  const {
    trigger: sendVerificationCode
  } = useSendVerificationCode({
    email,
    firstName
  });
  const {
    trigger: checkVerificationCode,
    isMutating: codeIsValidating
  } = useCheckVerificationCode({
    _email: email,
    _code: code
  });
  useRunOnce({
    fn: () => {
      sendVerificationCode();
    }
  });
  const onSubmit: SubmitHandler<VerificationCodeSchemaT> = useCallback(data => {
    return checkVerificationCode(undefined, {
      onSuccess: () => {
        onSuccess(data);
      },
      onError: error => {
        setError('verificationCode.code', {
          message: error.message,
          type: 'validate'
        });
      }
    });
  }, [checkVerificationCode, onSuccess]);
  return <Stack spacing={2} component="form" noValidate onSubmit={handleSubmit(onSubmit)} data-sentry-element="Stack" data-sentry-component="VerificationCodeStepForm" data-sentry-source-file="verification-code.step.tsx">
      <Typography data-sentry-element="Typography" data-sentry-source-file="verification-code.step.tsx">
        <Trans data-sentry-element="Trans" data-sentry-source-file="verification-code.step.tsx">
          Renseignez le code à 6 chiffres reçu sur votre adresse email pour
          valider votre inscription.
        </Trans>
      </Typography>

      <Stack spacing={1} alignItems="center" justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="verification-code.step.tsx">
        <OtpInput control={control} name="verificationCode.code" valueLength={6} disabled={codeIsValidating || isSubmitSuccessful} data-sentry-element="OtpInput" data-sentry-source-file="verification-code.step.tsx" />

        <Button variant="text" color="gray" size="small" onClick={() => sendVerificationCode()} disabled={codeIsValidating || isSubmitSuccessful} data-sentry-element="Button" data-sentry-source-file="verification-code.step.tsx">
          <Trans data-sentry-element="Trans" data-sentry-source-file="verification-code.step.tsx">Renvoyer le mail de vérification</Trans>
        </Button>
      </Stack>
      <Button type="submit" disabled={!isValid || codeIsValidating || isSubmitSuccessful} variant="contained" color="primary" data-sentry-element="Button" data-sentry-source-file="verification-code.step.tsx">
        {haveNextStep ? t`Suivant` : t`M'inscrire`}
      </Button>
    </Stack>;
};